import React, { useEffect, useRef, useState } from "react";
import { StyledMap, StyledMapMap } from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";

const addMarkers = (map, markers) => {
  const infowindows = [];

  let bounds = new google.maps.LatLngBounds();
  const res = markers.map((item) => {
    const icon = item.pin
      ? {
        url: item.pin,
        scaledSize: new google.maps.Size(38, 56),
      }
      : null;
    const markerConfig = {
      position: item.center,
      map: map,
      icon,
    };
    const marker = new google.maps.Marker(markerConfig);
    const infowindow = new google.maps.InfoWindow({
      content: item.infowindow || `<img src="${item.logo}" loading="lazy" />`,
    });

    infowindows.push(infowindow);
    marker.addListener("click", () => {
      infowindows.map((x) => x.close());
      infowindow.open(map, marker);
      if (typeof item.onClick === "function") {
        item.onClick();
      }
    });
    bounds.extend(item.center);
    return marker;
  });

  map.fitBounds(bounds);
  return res;
};

export default ({ center, markers, onMapReady }) => {
  const [map, setMap] = useState(false);
  const [pins, setPins] = useState([]);
  const mapEl = useRef(null);

  useEffect(() => {
    if (map && typeof onMapReady === "function") {
      onMapReady(map);
    }
  }, [map]);

  if (typeof window !== "undefined") {
    window.initMap = function () {
      setMap(
        new google.maps.Map(mapEl.current, {
          zoom: 7,
          maxZoom: 10,
          disableDefaultUI: true,
          mapTypeId: "hybrid",
          center: center || {
            lat: 45.252297711040974,
            lng: 11.374866783353825,
          },
        }),
      );
    };
  }

  useEffect(() => {
    let script = document.querySelector("#gmapsscript");
    if (!script) {
      script = document.createElement("script");
      document.querySelector("head").appendChild(script);
      script.id = "gmapsscript";
      script.async = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAP_KEY}&callback=initMap`;
    } else {
      initMap();
    }
  }, []);

  useEffect(() => {
    if (map) {
      map.setOptions({ maxZoom: 10 });
      pins.map((pin) => pin.setMap(null));
      setPins(addMarkers(map, markers));
      setTimeout(() => map.setOptions({ maxZoom: null }), 250);
      /*map.addListener('click', () => {
        console.log(map.getCenter().lat(), map.getCenter().lng())
      })*/
    }
  }, [map]);

  return (
    <StyledGridRow noMargin isFullMobile>
      <StyledMap>
        <StyledMapMap ref={mapEl} />
      </StyledMap>
    </StyledGridRow>
  );
};
